<template>
	<div>
		<div class="queryBox">
			<div class="mb20">
				<span class="mr20"><b>今日统计:</b></span>
				<span class="inlineBlock mr20">补单数：<span class="cdanger">{{countAll.bd_count}}</span></span>
				<span class="inlineBlock mr20">补增订单数：<span class="cdanger">{{countAll.bz_count}}</span></span>
				<span class="inlineBlock mr20">撤单数：<span class="cdanger">{{countAll.cd_count}}</span></span>
				<span class="inlineBlock mr20">补单金额：<span class="cdanger">{{countAll.bd_money | tofixed}}</span></span>
				<span class="inlineBlock mr20">补增订单金额：<span class="cdanger">{{countAll.bz_money | tofixed}}</span></span>
				<span class="inlineBlock mr20">撤单金额：<span class="cdanger">{{countAll.cd_money | tofixed}}</span></span>
				<!-- <span class="inlineBlock mr20">成功金额：<span class="cdanger">{{countAll.success_money | tofixed}}</span></span> -->
				<!-- <span class="inlineBlock mr20">成功笔数：<span class="cdanger">{{countAll.success_count}}</span></span> -->
			</div>
			<!-- <div class="mb20">
				<el-input size="mini" placeholder="请输入订单号" v-model="order_id" clearable class="mr20" style="width: 200px"></el-input>
				<el-input size="mini" placeholder="请输入交易单号" v-model="water_no" clearable class="mr20" style="width: 200px"></el-input>
				<el-input size="mini" placeholder="请输入商家号" v-model="business_no" clearable class="mr20" style="width: 200px"></el-input>
				<el-input size="mini" placeholder="请输入银行卡持有人名称" v-model="card_name" clearable class="mr20" style="width: 200px"></el-input>
				<el-input size="mini" placeholder="请输入商家名称" v-model="business_name" clearable class="mr20" style="width: 200px"></el-input>
				<el-input size="mini" placeholder="请输入提交金额" v-model="add_gold" clearable class="mr20" style="width: 200px"></el-input>
				<el-input size="mini" placeholder="请输入IP" v-model="req_ip" clearable class="mr20" style="width: 200px"></el-input>

			</div> -->
			<div class="mb20">
				<span>
					<span class="mr20">订单类型：</span>
					<el-select v-model="orderType" placeholder="订单类型" size="mini" class="mr20">
						<el-option v-for="item in orderTypeArr" :key="item.key" :label="item.name" :value="item.key"></el-option>
					</el-select>
				</span>
				<!-- <el-select v-if="orderType == '1'" v-model="orderType" placeholder="补单类型" size="mini" class="mr20">
					<el-option v-for="(item,index) in orderTypeArr" :key="index" :label="item" :value="index"></el-option>
				</el-select> -->
				<el-button size="mini" plain class="mr20" @click="setDate(-1)" :disabled="disLoading">前一天</el-button>
				<el-date-picker size="mini" v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期" class="mr20"></el-date-picker>
				<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">后一天</el-button>

				<el-select v-model="pay_type" placeholder="请选择通道" size="mini" class="mr20" clearable>
					<el-option v-for="item in paywayArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<!-- {{stateArr}} -->
				<el-select v-model="state" placeholder="请选择状态" size="mini" class="mr20" clearable>
					<el-option v-for="(item,index) in stateArr" :key="index" :label="item" :value="index"></el-option>
				</el-select>
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" @click="getData" 
				 :disabled="disLoading">查询</el-button>
				<!-- <el-button size="mini" type="primary" icon="el-icon-document" @click="getExcel" :disabled="tableData.length == 0">导出Excel</el-button> -->
			</div>
		</div>
		<template>
			<el-table size="mini" :max-height="height" :data="tableData" border="" stripe v-loading="loading":show-overflow-tooltip="true">
				<!-- <el-table-column type="index" label="序号"></el-table-column> -->
				<el-table-column prop="order_id" label="订单编号" width="170px" align="center">
					<template slot-scope="scope">
						<div class="textCenter" v-if="scope.row.id">
							{{scope.row.order_id}}
						</div>
						<div class="textCenter" v-if="!scope.row.id">
							<span v-if="scope.$index == tableData.length-2">当页总计</span>
							<span v-if="scope.$index == tableData.length-1">总计</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="water_no" label="交易号" width="170px" align="center">
					<template slot-scope="scope">
						<div class="textCenter" v-if="scope.row.id">
							{{scope.row.water_no}}
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="water_no" label="商家" min-width="200px"></el-table-column> -->
				<el-table-column label="商家" width="190px" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.id">
							<div class="textCenter">
								{{scope.row.business_name}}
							</div>
							<div class="textCenter">
								【{{scope.row.business_no}}】
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="通道" width="100px" prop="pay_type_name" align="center">
					<template slot-scope="scope">
						<div class="textCenter" v-if="scope.row.id">
							{{scope.row.pay_type_name}}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="账号" width="180px" align="center">
					<template slot-scope="scope">
						<div class="textCenter" v-if="scope.row.id">
							<el-link type="primary" @click="showInfo(scope.row)" v-if="scope.row.true_name||scope.row.bank || scope.row.card">
								<span v-if="scope.row.true_name||scope.row.bank || scope.row.card">
									{{scope.row.true_name}} | {{scope.row.bank}}
									<br>
									{{scope.row.card}}
								</span>
							</el-link>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="补单原因" width="180px" align="center">
					<template slot-scope="scope">
						<div class="textCenter" v-if="scope.row.id">
							<span>
								{{replenishmentStateObj[scope.row.replenish_state]}}
							</span>
							<span>
								{{scope.row.replenish_note}}
							</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="订单金额" align="center">
					<template slot-scope="scope">
						{{scope.row.submit_gold |  tofixed}}
					</template>
				</el-table-column>
				<el-table-column label="实际金额" align="center">
					<template slot-scope="scope">
						{{scope.row.gold | tofixed}}
					</template>
				</el-table-column>
				<el-table-column label="订单状态" align="center">
					<template slot-scope="scope">
						<div :class="scope.row.state==1?'csuccess':scope.row.state==7?'cdanger':''">{{stateArr[scope.row.state]}}</div>
					</template>
				</el-table-column>
				<el-table-column label="Fee" align="center">
					<template slot-scope="scope">
						{{scope.row.fee_gold_tra | tofixed}}
					</template>
				</el-table-column>
				<el-table-column label="实际到账(元)" align="center">
					<template slot-scope="scope">
						{{scope.row.trader_gold | tofixed}}
					</template>
				</el-table-column>
				<!-- <el-table-column prop="pay_type_name" label="交易方式/账号"></el-table-column> -->
				<el-table-column label="提交时间 (是否扫码)" width="133px" align="center">
					<template slot-scope="scope">
						<div class="textCenter" v-if="scope.row.id">
							{{scope.row.req_ip}} {{scope.row.is_scaned==1?'是':'否'}}
							<br>
							{{scope.row.add_time}}
						</div>

					</template>
				</el-table-column>
				<el-table-column label="操作人" width="100px" prop="auditor" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.id" class="textCenter">
							{{scope.row.auditor}}
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.state == 1">
							<div v-if="scope.row.replenish_state==0">{{scope.row.bill_no}}</div>
							<div v-if="scope.row.replenish_state>0 || scope.row.auditor!=''">{{scope.row.auditor}}</div>
							<div v-if="scope.row.replenish_state>0">{{replenishmentStateObj[scope.row.replenish_state]}}</div>
							<div>
								<el-link type="primary" @click="noticeIn(scope.row,scope.$index)">发送回调</el-link>
							</div>
						</div>
						<div v-if="scope.row.state == 7">
							<el-link v-if="scope.row.card && scope.row.is_manual == 0" type="primary" @click="showDia(scope.row,scope.$index)">补单</el-link>
						</div>
						<div v-if="scope.row.state == 1">
							<el-link v-if="scope.row.card && scope.row.is_cancel == 0" type="primary" @click="ordercancel(scope.row,scope.$index)">撤单</el-link>
						</div>
					</template>
				</el-table-column> -->
				<el-table-column label="处理时间" width="200px" align="center">
					<template slot-scope="scope">
						<div v-if='scope.row.id && scope.row.action_time' class="textCenter">
							{{getTimeDifference(scope.row.add_time,scope.row.action_time)}}
							<br>
							{{scope.row.action_time}}
						</div>

					</template>
				</el-table-column>
			</el-table>
		</template>

		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr":page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			  :total="total"></el-pagination>
		</div>
		<el-dialog center class="dialog" top="100px" title="" :visible.sync="dialogVisible" @close="close" width="70%"
			:close-on-click-modal="false">
			<div class="formBox" v-loading="dialogloading">
				<div>
					<el-tabs type="border-card" @tab-click="getInfoData" v-model="activeName">
						<el-tab-pane label="搬砖工" name='info'>
							<div class="flexX flexAround flexTop">

								<div>
									<el-form label-position="right" label-width="80px">
										<el-form-item label="用户名:">{{infoTotalData.name}}</el-form-item>
										<el-form-item label="账号:">{{infoTotalData.username}}</el-form-item>
										<el-form-item label="Skype:">
											<!-- {{infoTotalData.skype}} -->
											<el-link type="primary" :href="`skype:${infoTotalData.skype}?chat`" οnclick="return skypeCheck();">{{infoTotalData.skype}}</el-link>
										</el-form-item>
										<el-form-item label="手机号:">{{infoTotalData.telephone}}</el-form-item>
										<el-form-item label="上级:">{{infoTotalData.all_parent_name}}</el-form-item>
										<el-form-item label="银行:">{{infoTotalData.bank}}</el-form-item>
										<el-form-item label="卡号:">{{infoTotalData.card}}</el-form-item>
										<el-form-item label="姓名:">{{infoTotalData.true_name}}</el-form-item>
									</el-form>
								</div>
								<div>
									<el-form label-position="right" label-width="80px">
										<el-form-item label="提现金额:">{{infoTotalData.tx_money | tofixed}}</el-form-item>
										<!-- <el-form-item label="提成:">{{infoTotalData.total_tc | tofixed}}</el-form-item> -->
										<el-form-item label="今日奖金:">{{infoTotalData.today_yj | tofixed}}</el-form-item>
										<el-form-item label="今日提成:">{{infoTotalData.today_tc | tofixed}}</el-form-item>
										<el-form-item label="卡数量:">{{infoTotalData.card_count}}</el-form-item>
										<el-form-item label="充值状态:"> <span	:class="infoTotalData.pay_state==0?'csuccess':'cdanger'">{{infoTotalData.pay_state==0?'启用':'禁用'}}</span></el-form-item>
										<el-form-item label="代付状态:"> <span	:class="infoTotalData.repay_state==0?'csuccess':'cdanger'">{{infoTotalData.repay_state==0?'启用':'禁用'}}</span></el-form-item>
										<el-form-item label="通道:">{{infoTotalData.business_name}}</el-form-item>
									</el-form>
								</div>
							</div>
						</el-tab-pane>
						<!-- <el-tab-pane label="卡列表" name='bankList'>
							<div class="mb20"><span class="mr20">用户名：{{infoTotalData.name}}</span><span>账号：{{infoTotalData.username}}</span></div>
							<el-table size="mini" :data="cardListData" border="" stripe max-height="500px">
								<el-table-column prop="card" label="卡号" width="145px" align="center"></el-table-column>
								<el-table-column prop="bank" label="银行" align="center"></el-table-column>
								<el-table-column prop="true_name" label="姓名" align="center"></el-table-column>
								<el-table-column prop="telephone" label="电话" align="center"></el-table-column>

								<el-table-column label="昨日结余" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.yes_true_quota | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="今日收款" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.money_in | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="今日出款" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.money_out | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column label="当前余额" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.true_quota | tofixed}}</div>
									</template>
								</el-table-column>
							</el-table>
							<div class="flexX  mt20">
								<el-pagination :hide-on-single-page="true" @size-change="diahandleSizeChange" @current-change="diahandleCurrentChange"
								 :current-page="diapage" :page-sizes="diapageSizesArr" :page-size="diapageSize" layout="total, sizes, prev, pager, next, jumper"
								 :total="diatotal"></el-pagination>
							</div>
						</el-tab-pane> -->
						<el-tab-pane label="卡进账订单" name='cardNote'>
							<div class="mb20">
								<span class="mr20">用户名：{{infoTotalData.name}}</span><span class="mr20">账号：{{infoTotalData.username}}</span>
								<span>时间：</span>
								<el-date-picker size="mini" v-model="dateIn" type="datetimerange" range-separator="至" start-placeholder="开始日期"
								 end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" class="mr20"></el-date-picker>
								 <el-button size="mini" type="primary" :icon="disLoadingIn?'el-icon-loading':'el-icon-search'" @click="getDataIn('in')" :disabled="disLoadingIn">搜索</el-button>
							</div>
							<el-table size="mini" :data="cardNoteData" border="" stripe max-height="500px">
								<el-table-column prop="true_name" label="姓名" align="center">
									<template slot-scope='scope'>
										<div v-if="scope.row.id">{{scope.row.true_name}}</div>
										<div v-if="!scope.row.id">{{scope.row.name}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="card" label="卡号" align="center"></el-table-column>
								<el-table-column prop="order_id" label="订单号" align="center"></el-table-column>

								<el-table-column label="金额" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.gold | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="action_time" label="时间" align="center"></el-table-column>

							</el-table>
							<div class="flexX  mt20">
								<el-pagination :hide-on-single-page="true" @size-change="nodehandleSizeChange"	@current-change="nodehandleCurrentChange"
								 :current-page="nodepage" :page-sizes="nodepageSizesArr" :page-size="nodepageSize" layout="total, sizes, prev, pager, next, jumper"
								 :total="nodetotal"></el-pagination>
							</div>
						</el-tab-pane>

						<el-tab-pane label="卡出账订单" name='cardNoteOut'>
							<span>时间：</span>
							<el-date-picker size="mini" v-model="dateOut" type="datetimerange" range-separator="至" start-placeholder="开始日期"
							 end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" class="mr20"></el-date-picker>
							 <el-button size="mini" type="primary" :icon="disLoadingOut?'el-icon-loading':'el-icon-search'" @click="getDataIn('out')" :disabled="disLoadingOut">搜索</el-button>
							<el-table size="mini" :data="cardNoteOutData" border="" stripe max-height="500">
								<el-table-column prop="true_name" label="姓名" align="center">
									<template slot-scope='scope'>
										<div v-if="scope.row.id">{{scope.row.true_name}}</div>
										<div v-if="!scope.row.id">{{scope.row.name}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="card" label="卡号" align="center"></el-table-column>
								<el-table-column prop="order_id" label="订单号" align="center"></el-table-column>
								<el-table-column label="金额" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.gold | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="action_time" label="时间" align="center"></el-table-column>
							</el-table>
							<div class="flexX  mt20">
								<el-pagination :hide-on-single-page="true" @size-change="outhandleSizeChange" @current-change="outhandleCurrentChange" 
									:current-page="outpage":page-sizes="outpageSizesArr" :page-size="outpageSize"	layout="total, sizes, prev, pager, next, jumper"
									:total="outtotal"></el-pagination>
							</div>
						</el-tab-pane>

						<!-- 卡账单 -->
						<el-tab-pane label="卡账单" name='cardBill'>
							<div class="mb20">
								<span class="mr20">用户名：{{infoTotalData.name}}</span>
								<span class="mr20">账号：{{infoTotalData.username}}</span>
								<span class="mr20" v-if="cardBillData.length>0">姓名：{{cardBillData[0].name}}</span>
								<span class="mr20" v-if="cardBillData.length>0">卡号：{{cardBillData[0].card}}</span>
								<span>更新时间：{{bill_update_time}}</span>
							</div>
							<el-table size="mini" :data="cardBillData" border="" stripe max-height="500px">
								<el-table-column type="index" label="序号" align="center" width="50px"></el-table-column>
								<el-table-column prop="water_no" label="系统订单号" align="center" min-width="140px"></el-table-column>
								<el-table-column label="交易状态" align="center" min-width="140px">
									<template slot-scope='scope'>
										<div v-if="scope.row.state_name">{{scope.row.state_name}}</div>
										<div v-if="scope.row.type == 1 && (scope.row.state == 7 || scope.row.water_no == '')">
											<el-link type="primary"	@click="billshowDia(scope.row,scope.$index,'billadd')">补单</el-link>
										</div>
										<div v-if="scope.row.type == 0 && scope.row.water_no == '' && scope.row.repay_is_loss == 0">
											<el-link type="danger"	@click="billshowDia(scope.row,scope.$index,'billloss')">报损</el-link>
										</div>
										<div v-if="scope.row.type == 0 && scope.row.water_no == '' && scope.row.repay_is_loss == 1">
											已报损
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="trans_date" label="交易时间" align="center" min-width="140px">	</el-table-column>
								<el-table-column label="入款记录" align="center">
									<template slot-scope='scope'>
										<div v-if="scope.row.type == 1">{{scope.row.amount | tofixed}}</div>
										<!-- <div v-if="scope.row.type == 0">0</div> -->
									</template>
								</el-table-column>
								<el-table-column label="出款记录" align="center">
									<template slot-scope='scope'>
										<div v-if="scope.row.type == 0">{{scope.row.amount | tofixed}}</div>
										<!-- <div v-if="scope.row.type == 1">0</div> -->
									</template>
								</el-table-column>
								<el-table-column prop="transplace" label="收支摘要" align="center" min-width="140px">
									<template slot-scope='scope'>
										<div :class="scope.row.type=='0'?'cdanger':'csuccess'">{{scope.row.transplace}}	</div>
									</template>
								</el-table-column>
								<el-table-column label="账户余额" align="center">
									<template slot-scope='scope'>
										<div>{{scope.row.keep_amount | tofixed}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="pay_card" label="对方账户" align="center" min-width="140px"></el-table-column>
								<el-table-column prop="pay_name" label="对方户名" align="center" width="140px"></el-table-column>
								<el-table-column prop="remark" label="备注" align="center" width="140px"></el-table-column>

							</el-table>
							<div class="flexX  mt20">
								<el-pagination :hide-on-single-page="true" @size-change="billhandleSizeChange"	@current-change="billhandleCurrentChange" 
								:current-page="billpage" :page-sizes="billpageSizesArr" :page-size="billpageSize" layout="total, sizes, prev, pager, next, jumper" 
								:total="billtotal"></el-pagination>
							</div>
						</el-tab-pane>


					</el-tabs>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="close">关闭</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog width="800px" center top="150px" title="补单" :visible.sync="bddialogVisible" @close="bdclose"
			:close-on-click-modal="false">
			<div class="formBoxbd" v-loading="bddialogloading">
				<div>
					<el-form label-position="right" label-width="100px">
						<el-form-item label="订单编号">
							{{item.order_id}}
						</el-form-item>
						<el-form-item label="交易号">
							{{item.water_no}}
						</el-form-item>
						<el-form-item label="商家名称">
							{{item.business_name}}
						</el-form-item>
						<el-form-item label="订单金额">
							{{item.add_gold | tofixed}}元
						</el-form-item>
						<el-form-item label="补单类型">
							<el-select v-model="replenState" placeholder="请选择补单类型" size="mini" class="mr20" clearable>
								<el-option v-for="(item,index) in replenishmentStateObj" :key="index" :label="item":value="index"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="补单金额">
							<el-input v-model="amount" placeholder="请输入补单金额"></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmit">确定</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog width="800px" center top="150px" :title="billTitle" :visible.sync="billbddialogVisible" @close="billbdclose" 
			:close-on-click-modal="false">
			<div class="formBoxbd" v-loading="billbddialogloading">
				<div v-if="billType == 'billadd'">
					<el-form label-position="right" label-width="100px">
						<el-form-item label="金额">
							{{billbditem.amount | tofixed}}元
						</el-form-item>
						<el-form-item label="订单编号">
							<el-select v-model="failOrder" filterable placeholder="请选择订单编号" size="mini" class="mr20"
								clearable>
								<el-option v-for="(item) in failOrderList" :key="item.id"
									:label="item.trader_order_id+item.order_id" :value="item.id">
									<span>{{item.trader_order_id}} | {{item.order_id}} | {{item.business_name}} |
										{{item.add_gold | tofixed}} | {{item.add_time}}</span>
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="补单类型">
							<el-select v-model="replenState" placeholder="请选择补单类型" size="mini" class="mr20" clearable>
								<el-option v-for="(item,index) in replenishmentStateObj" :key="index" :label="item"
									:value="index"></el-option>
							</el-select>

						</el-form-item>
					</el-form>
				</div>
				<div v-if="billType == 'billloss'">
					<el-form label-position="right" label-width="100px">

						<el-form-item label="姓名">
							{{item.true_name}}
						</el-form-item>
						<el-form-item label="银行">
							{{item.bank}}
						</el-form-item>
						<el-form-item label="卡号">
							{{item.card}}
						</el-form-item>
						<el-form-item label="搬砖工">
							{{infoTotalData.name}}
						</el-form-item>
						<!-- <el-form-item label="差值">
								<span>{{item.true_quota - item.sys_quota  | tofixed}}</span>
							</el-form-item> -->
						<el-form-item label="金额">
							{{billbditem.amount | tofixed}}元
						</el-form-item>

						<el-form-item label="报损类型">
							<el-select v-model="losseType" filterable placeholder="请选择报损类型" size="mini" class="mr20"
								clearable>
								<el-option v-for="(item) in losseTypeList" :key="item.id" :label="item.label"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="备注">
							<el-input size="mini" v-model="losseNote" placeholder="请输入备注" style="width: 200px;">
							</el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="billonSubmit">确定</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import local from '@/util/local'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMobile,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				tableData: [],
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				diapageSizesArr: [20, 50, 100, 200],
				diapageSize: 20,
				diatotal: 0,
				diapage: 1,
				nodepageSizesArr: [20, 50, 100, 200],
				nodepageSize: 20,
				nodetotal: 0,
				nodepage: 1,
				outpageSizesArr: [20, 50, 100, 200],
				outpageSize: 20,
				outtotal: 0,
				outpage: 1,
				billpageSizesArr: [20, 50, 100, 200],
				billpageSize: 20,
				billtotal: 0,
				billpage: 1,
				order_id: '',
				water_no: '',
				business_no: '',
				card_name: '',
				business_name: '',
				add_gold: '',
				req_ip: '',
				pay_type: '',
				state: '',
				paywayArr: [],
				stateArr: {},
				// stateMap:{},
				date: null,
				countAll: {
					total_money: 0,
					success_money: 0,
					total_count: 0,
					success_count: 0,
				},
				dialogVisible: false,
				dialogloading: false,
				item: {},
				infoTotalData: {}, //搬砖工
				cardListData: [], //银行卡列表
				cardNoteData: [], //银行卡记录  流水
				cardNoteOutData: [], //卡出账记录 
				cardBillData: [], //卡账单
				bill_update_time: '', //卡账单 最后更新时间
				activeName: 'info',
				//补单
				replenState: '',
				replenishmentStateObj: {}, //补单类型
				bddialogVisible: false, //补单弹框
				amount: '', //补单金额
				item: {}, //当前补单的数据
				index: {}, //当前item 索引
				bddialogloading: false,
				//orderType type   //订单类型 1-补单+补增 2-补单 3-补增 4-撤单
				orderType: 1,
				orderTypeArr: [{
					key: 1,
					name: "所有补单",
				}, {
					key: 2,
					name: "正常补单",
				}, {
					key: 3,
					name: "补增订单",
				}, {
					key: 4,
					name: "撤单",
				}],

				//卡账单补单
				// replenState: '',
				// replenishmentStateObj: {}, //补单类型
				billbddialogVisible: false, //补单弹框
				billbditem: {}, //当前补单的数据
				index: {}, //当前item 索引
				// orderType:"",//订单操作类型  cancle 撤单
				billbddialogloading: false,
				failOrderList: [],
				failOrder: '',
				//报损
				lossmoney: '',
				losseType: '',
				losseTypeList: [{
					id: '1',
					label: '从搬砖工扣除'
				}, {
					id: '2',
					label: '从平台扣除'
				}],
				losseNote: '',
				//卡账单报损
				billType: '', //billadd 卡账单补单   billloss 卡账单报损
				billTitle: '', //账单弹出框title
				height: 0,
				dateIn:null,
				dateOut:null,
				disLoadingIn:false,
				disLoadingOut:false
			};
		},
		created() {
			this.date = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date()
				.getTime(),
				true)];
			this.paywayAll()
			this.stateList()
			this.orderAllList()
			this.orderCountAll()
			this.replenishmentState()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				// let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// console.log('sssssssssss', (value * 1).toFixed(2) + '')
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val = Math.floor(((value * 1) * 100).toPrecision(12))
					return ((val / 100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			setDate(day) {
				let data = this.date ? this.date[0] : this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate, newDate]
				this.getData()
			},
			getTimeDifference(startTime, endTime) {
				return this.$util.intervalTime(startTime, endTime)
			},
			//搜索按钮
			getData() {
				this.page = 1;
				this.orderAllList();
				this.orderCountAll();
			},
			//总计
			orderCountAll() {
				this.$api.replenishmentCountAll({}).then(res => {
					console.log(res)
					if (res.status == 1) {
						this.countAll = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//导出Excel
			getExcel() {
				//manager/order/exportList
				console.log(this.$baseURL)
				// let data = {
				let username = local.get('username');
				let tid = local.get('tid')
				let start_time = this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), true) : '';
				let end_time = this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), true) : '';
				// }
				let href =
					`${this.$baseURL}/manager/order/exportList?username=${username}&tid=${tid}&order_id=${this.order_id}&water_no=${this.water_no}&business_no=${this.business_no}&card_name=${this.card_name}&business_name=${this.business_name}&add_gold=${this.add_gold}&req_ip=${this.req_ip}&pay_type=${this.pay_type}&state=${this.state}&start_time=${start_time}&end_time=${end_time}`
				window.location.href = href
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val
				this.page = 1
				this.orderAllList()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.orderAllList()
			},
			diahandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.diapage = 1
				this.diapageSize = val
				this.cardList()
			},
			diahandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.diapage = val
				this.cardList()
			},

			nodehandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.nodepageSize = val
				this.nodepage = 1
				this.moneyList()
			},
			nodehandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.nodepage = val
				this.moneyList()
			},
			outhandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.outpage = 1
				this.outpageSize = val
				this.outList()
			},
			outhandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.outpage = val
				this.outList()
			},
			billhandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.billpage = 1
				this.billpageSize = val
				this.cardBill()
			},
			billhandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.billpage = val
				this.cardBill()
			},

			paywayAll() {
				this.$api.paywayAll({
					hasRepay: 2
				}).then(res => {
					if (res.status === 1) {
						this.paywayArr = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			stateList() {
				this.$api.stateList({}).then(res => {
					if (res.status === 1) {
						this.stateArr = res.data
						// this.stateArr.forEach(item=>{
						// 	this.stateMap[item.id] = item.note
						// })
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			orderAllList() {
				let data = {
					type: this.orderType,
					order_id: this.order_id,
					water_no: this.water_no,
					business_no: this.business_no,
					card_name: this.card_name,
					business_name: this.business_name,
					add_gold: this.add_gold,
					req_ip: this.req_ip,
					pay_type: this.pay_type,
					state: this.state,
					// start_time: this.date?this.date[0]:'',
					// end_time:  this.date?this.date[1]:'',
					start_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), true) : '',
					end_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), true) : '',
					page: this.page,
					size: this.pageSize,
				}
				// this.loading = true
				this.disLoading = true
				console.log('disLoading', this.disLoading)
				this.$api.orderAllList(data).then(res => {
					this.loading = false;
					this.disLoading = false;
					if (res.status === 1) {
						this.tableData = res.data.data;
						this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
			showInfo(item) {
				// this.$router.push({path:'/orderInfo'})
				this.dialogVisible = true;
				this.item = item
				this.infoTotal(item)
			},
			getInfoData(val) {
				if (val.name == 'info') {
					if (JSON.stringify(this.infoTotalData) == '{}') {
						this.infoTotal()
					}
				}
				if (val.name == 'bankList') {
					if (this.cardListData.length == 0) {
						this.cardList()
					}
				}
				if (val.name == 'cardNote') {
					// this.dateIn = [this.$util.timestampToTime(new Date().getTime(), true) + ' 00:00:00', this.$util.timestampToTime(new Date().getTime(), true) + ' 23:59:59']
					if (this.cardNoteData.length == 0) {
						this.moneyList()
					}
				}
				if (val.name == 'cardNoteOut') {
					// this.dateIn = [this.$util.timestampToTime(new Date().getTime(), true) + ' 00:00:00', this.$util.timestampToTime(new Date().getTime(), true) + ' 23:59:59']					
					if (this.cardNoteOutData.length == 0) {
						this.outList()
					}
				}
				if (val.name == 'cardBill') {
					if (this.cardBillData.length == 0) {
						this.cardBill()
					}
				}
				console.log('val', val)
			},
			infoTotal(item) {
				let data = {
					id: item.alipay_id,
					type: 3
				}
				// this.dialogloading = true
				this.$api.infoTotal(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.infoTotalData = res.data
					} else {
						this.$message({
							"type": "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			cardList(item) {
				let data = {
					carder_id: this.infoTotalData.id,
					size: this.diapageSize,
					page: this.diapage,
					// type:2
				}

				// this.dialogloading = true
				this.$api.cardList(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.cardListData = res.data.data
						this.diatotal = res.data.total

					} else {
						this.$message({
							"type": "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			close() {
				this.dialogVisible = false
				this.item = {}
				this.infoTotalData = {} //搬砖工
				this.cardListData = [] //银行卡列表
				this.cardNoteData = [] //银行卡记录  流水
				this.cardNoteOutData = []
				this.cardBillData = []
				this.activeName = 'info'
				this.diapage = 1
				this.nodepage = 1
				this.outpage = 1
				this.billpage = 1
				this.dateIn=null
				this.dateOut=null
				this.disLoadingIn=false
				this.disLoadingOut=false

			},

			//补单按钮
			replenishmentState() {
				this.$api.replenishmentState({}).then(res => {
					if (res.status == 1) {
						this.replenishmentStateObj = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//通知商家
			noticeIn(item, index) {
				this.$confirm('确认发送回调吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					this.$api.noticeIn(data).then(res => {
						this.loading = false;
						if (res.status == 1) {
							this.$message({
								type: 'success',
								message: "发送回调成功"
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消发送回调'
					});
				});
			},

			showDia(item, index) {
				this.item = item
				this.index = index
				// this.orderType=type
				this.bddialogVisible = true
				if (JSON.stringify(this.replenishmentStateObj) == '{}') {
					this.replenishmentState()
				}

			},
			//确认补单
			onSubmit() {
				if (isEmpty(this.replenState, '请选择补单类型')) {
					return
				}
				if (isEmpty(this.amount, '请选输入补单金额')) {
					return
				}
				if (!isMoney(this.amount, '补单金额只能是数字，且最多保留两位小数')) {
					return
				}
				let data = {
					id: this.item.id,
					state: this.replenState,
					amount: this.amount
				}
				this.bddialogloading = true;
				this.$api.replenishment(data).then(res => {
					this.bddialogloading = false;
					if (res.status == 1) {
						this.orderAllList()
						this.bdclose();
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(() => {
					this.bddialogloading = false;
				})
			},
			bdclose() {
				this.item = {}
				this.bddialogVisible = false
				this.replenState = ''
				this.amount = ''
			},
			moneyList() {
				let data = {
					page: this.nodepage,
					size: this.nodepageSize,
					id: this.item.bank_card_id,
					startdate:this.dateIn?this.$util.timestampToTime(Date.parse(new Date(this.dateIn[0]))):"",
					endate:this.dateIn?this.$util.timestampToTime(Date.parse(new Date(this.dateIn[1]))):""
				}
				this.disLoadingIn = true
				// this.dialogloading = true
				this.$api.moneyList(data).then(res => {
					this.dialogloading = false
					this.disLoadingIn = false
					if (res.status == 1) {
						this.cardNoteData = res.data.data
						this.nodetotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {					
					this.disLoadingIn = false
					this.dialogloading = false
				})
			},
			outList() {
				let data = {
					page: this.outpage,
					size: this.outpageSize,
					id: this.item.bank_card_id,
					startdate:this.dateOut?this.$util.timestampToTime(Date.parse(new Date(this.dateOut[0]))):"",
					endate:this.dateOut?this.$util.timestampToTime(Date.parse(new Date(this.dateOut[1]))):""
				}
				
				this.disLoadingOut = true
				// this.dialogloading = true
				this.$api.outList(data).then(res => {
					this.dialogloading = false
					this.disLoadingOut = false
					if (res.status == 1) {
						this.cardNoteOutData = res.data.data
						this.outtotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
					this.disLoadingOut = false
				})
			},
			//卡账单
			cardBill() {
				let data = {
					page: this.billpage,
					size: this.billpageSize,
					id: this.item.bank_card_id,
				}
				// this.dialogloading = true
				this.$api.cardBill(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.cardBillData = res.data.data
						this.billtotal = res.data.total
						this.bill_update_time = res.data.bill_update_time
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			//撤单
			ordercancel(item, index) {
				this.$confirm('确认撤单' + item.order_id + '吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					this.$api.ordercancel(data).then(res => {
						this.loading = false
						if (res.status == 1) {
							this.orderAllList()
							this.$message({
								type: 'success',
								message: '撤单成功'
							});
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
					}).catch(error => {
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消撤单'
					});
				});
			},
			//卡账单补单
			billshowDia(item, index, type) {
				this.billbditem = item
				this.billType = type
				this.billbddialogVisible = true
				if (this.billType == "billadd") {
					//补单
					this.billTitle = "补单"
					if (JSON.stringify(this.replenishmentStateObj) == '{}') {
						this.replenishmentState()
					}
					this.getFailOrder()
				} else if (this.billType == "billloss") {
					this.billTitle = "银行卡报损"
				}
			},
			//补单类型
			replenishmentState() {
				this.$api.replenishmentState({}).then(res => {
					if (res.status == 1) {
						this.replenishmentStateObj = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//根据卡查找失败的订单
			getFailOrder() {
				let data = {
					card: this.billbditem.card
				}
				this.$api.getFailOrder(data).then(res => {
					this.failOrderList = res.data
				})
			},
			//确认补单
			billonSubmit() {
				if (this.billType == "billadd") {
					if (isEmpty(this.failOrder, '请选择订单')) {
						return
					}
					if (isEmpty(this.replenState, '请选择补单类型')) {
						return
					}
					let amount = ''
					for (let i = 0; i < this.failOrderList.length; i++) {
						if (this.failOrderList[i].id == this.failOrder) {
							amount = this.failOrderList[i].add_gold
							break;
						}
					}
					let data = {
						id: this.failOrder,
						state: this.replenState,
						amount: amount,
						bill_id: this.billbditem.id
					}
					// console.log(data)
					this.billbddialogloading = true;
					this.$api.replenishment(data).then(res => {
						this.billbddialogloading = false;
						if (res.status == 1) {
							this.cardBill()
							this.billbdclose();
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(() => {
						this.billbddialogloading = false;
					})
				} else if (this.billType == "billloss") {
					this.lossmoney = parseFloat(this.billbditem.amount).toFixed(2)
					console.log(this.lossmoney)
					this.lossSubmit('bill', this.billbditem.id)
				}
			},
			//关闭补单弹框
			billbdclose() {
				this.billbditem = {}
				this.billbddialogVisible = false
				this.replenState = ''
				this.failOrder = ''
				this.lossmoney = ''
				this.losseType = ''
				this.losseNote = ''
				this.failOrderList = []
			},
			//报损
			lossSubmit(bill, id) {
				if (!isMoney(this.lossmoney, '报损金额只能是数字，最多输入两位小数')) {
					return
				}
				if (isEmpty(this.losseType, '请选择报损类型')) {
					return
				}
				let data = {
					type: this.losseType,
					bank_card_id: this.item.bank_card_id,
					money: this.lossmoney,
					note: this.losseNote
				}
				if (bill == "bill") {
					data.bill_id = id
				}
				this.lossdialogloading = true
				this.$api.addBankCardLoss(data).then(res => {
					this.lossdialogloading = false
					if (res.status == 1) {
						this.orderAllList()
						if (bill == "bill") {
							this.billbdclose()
							this.cardBill()
						} else {
							this.lossclose()
						}
						this.$message({
							type: "success",
							message: res.msg
						})
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.lossdialogloading = false
				})
				
			},
			getDataIn(type){
				if(type == 'in'){
					this.moneyList()					
				}
				else if(type == 'out'){
					this.outList()
				}
			},
		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					// let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
				})
			}
		},
	};
</script>

<style scoped="scoped">
	.formBoxbd {
		width: 80%;
		margin: 0 auto;
	}
</style>
